import { oneOf, string } from 'prop-types';
import classes from './SlideLogo.module.scss';
import React from 'react';

const SlideLogo = ({ logoType, company, dimensions, logoPosition }) => {
  const slideLogo = `./assets/images/${company?.toLowerCase()}_logo_${logoType}.png`;
  const className = `${classes.slideLogo} ${
    classes[`${company?.toLowerCase().replace(' ', '')}Logo`]
  } ${classes[logoPosition]}`;

  return <img src={slideLogo} alt={slideLogo} className={className} />;
};

SlideLogo.propTypes = {
  logoType: oneOf(['white', 'black']),
  company: string.isRequired,
  logoPosition: oneOf(['bottom', 'center', 'top', null]),
};

export default SlideLogo;
